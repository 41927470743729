body {
  margin: 0;
  padding: 0;
  font-size: 25px;
  color: #202020;
  font-family: 'Crimson Text', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}


#writer {
    padding: 1em;
    border-radius: 0.1em;
    max-width: 24em;
    margin: 0 auto;
    margin-bottom: 5em;
}

.editor {
    min-height: 20em;
    text-align: justify;
    width: 100%;
}
.sidebar {
    position: relative;
    flex-grow: 0;
    flex-basis: auto;
    background-color: #f6f4f3;
    box-shadow: inset -10px 0px 10px -10px rgba(0,0,0,0.2);
}

.sidebar-content {
    height: 100%;
}

.sidebar.is-visible  ~ .content {
    flex-basis: 0;
}

.content {
    -webkit-transition: flex-basis .2s ease-in-out;
    transition: flex-basis .2s ease-in-out;
    will-change: flex-basis;
}

.openclose {
    position: absolute;
    top: 1em;
    right: -2em;

    opacity: 0.5;
    
    cursor: pointer;
}

.openclose:hover {
    opacity: 0.9;
}

.add {
    cursor: pointer;
    
    min-width: 1em;
    margin-right: 1em;
    padding: 0.2em;
    border-radius: 50%;
    width: 1em;
    height: 1em;
    background: #dcdcdc;
    color: #333333;
    
    position: absolute;
    left: 1em;
}

.app {
    display: flex;
    justify-content: flex-end;
    height: 100%;
    min-height: 100vh;
}

.content {
    display: flex;
    min-height: 0;
    flex-direction: column;
    
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 100%;
}

