
.app {
    display: flex;
    justify-content: flex-end;
    height: 100%;
    min-height: 100vh;
}

.content {
    display: flex;
    min-height: 0;
    flex-direction: column;
    
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 100%;
}
